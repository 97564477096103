import React from "react"
import { 
    // Link,
    graphql 
} from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from "../../utils/typography"

class OnTheGoEnglishClass extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="OnTheGoEnglishClass" />
        <div style={{ position: 'relative', padding: '6rem 1.2rem 4rem', minHeight: '350px', }}>
          <Image 
            style={{ opacity: '.4', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,}}
            fluid={data.backgroundImage.childImageSharp.fluid} 
          />
          <div style={{ maxWidth: '1000px', textAlign: 'center', margin: '0 auto', position: 'relative'}}>
            <Title>On-The-Go English Class: Viber Pilot Program</Title>
            <div style={{ maxWidth: '700px', margin: '0 auto'}}>
              <span style={{fontWeight: '600', }}>Desiging professional development for nomadic Sales Associates</span>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#F9F6F7', padding: rhythm(1.5),}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading>Project Context</SectionHeading>
            <div>
                <p>
                  On-the-Go English Class was a pilot project that facilitated English Learning for Proximity Designs sales associates. I worked with Pyu Pyu and Zaw Zaw, two Proximity Designs coworkers, to learn about sales associates’ needs, design a custom solution and pilot bite-size English lessons delivered via Viber to two groups of sales associates.              
                </p>
            </div>
        </div>
        </div>
        <div style={{ backgroundColor: '#fff', padding: rhythm(1.5),}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading>The Problem: Making Time for Learning While Selling</SectionHeading>
            <p>The Management Team wanted to integrate learning into the weekly schedules of all Proximity staff, but the company’s Irrigation Team posed an especially difficult conundrum. Unlike other departments that had a main office where staff gathered daily for check-ins, the Irrigation Team's effectiveness was strongly correlated with how widely it could spread its figurative net; day after day, sales associates dispersed across regions of the country, trying to pitch Proximity Designs’ products to as many rural farmers as possible.</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center', flexWrap:' wrap', maxWidth: '790px', margin: '0 auto', justifyContent: 'start'}}> 
            <StyledMapImage fluid={data.myanmarmap.childImageSharp.fluid} />
            <div style={{ flex: '1 0 40%'}}>
              <span style={{fontWeight: '600'}}>Proximity Designs Regions and Zones</span>
              <p style={{ fontStyle: 'italic', fontSize: '.8rem', marginBottom: '0'}}>This map of Myanmar shows the Proximity Designs services and offices spread across the country. Pink: Microfinance Offices; Blue: Irrigation Team Zones; Green: Agriculture Offices. Our focus for this project was the blue post-its.</p>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#F9F6F7', padding: rhythm(1.5),}}>
            <div style={{ maxWidth: '780px', margin: '0 auto'}}>
                <SectionHeading>Discovery Phase: Understanding User Routines and Motivations</SectionHeading>
                <p>From the very beginning, we prioritized meeting with sales associates to understand their needs and levels of English. Before brainstorming a specific model of instruction, we wanted to visualize their day-to-day activities. Our main questions were:
                    <ol style={{ fontWeight: 600, marginTop: '5px', paddingLeft: '15px'}}>
                        <li style={{ marginBottom: '10px'}}>How do sales associates break out their daily activities?</li>
                        <li style={{ marginBottom: '10px'}}>What are sales associates' motivations for learning English and other skills?</li>
                    </ol>
                    We set up a meeting with 10 sales associates operating in one of the seven sales regions. We met them in the field and conducted one-on-one interviews, English placement tests, and a group Q&A. Here’s what we learned:
                </p>
                <SectionSubheading style={{ margin: '10px 0',}}>Reoccuring Themes</SectionSubheading>
                <p>
                    <ul style={{ marginTop: '5px', paddingLeft: '15px', }}>
                        <li><Accent className=" bold">Very Basic English</Accent> - The level of English was similar across the team, limited to basic greetings and common nouns like "family", "sales", "daughter", etc.</li>
                        <li><Accent className=" bold">Family-Oriented</Accent> - Most team members described wanting to learn English for their family, creating opportunities for their children, and serving as a role model.</li>
                        <li><Accent className=" bold">No Office</Accent> - Their jobs were extremely nomadic and unpredictable. One day of the week, a sales associate might be riding his motorbike for two hours to a sales presentation for farmers across town. The next day, he might drive to provide a replacement part for a drip system to a farmer one hour in the opposite direction.</li>
                        <li><Accent className=" bold">Heavy Reliance on Mobile</Accent> - Sales associates were on their phone often and very familiar with Viber. They used the application to check-in with their sales manager almost every day. They sometimes stopped in cafes throughout the day, checked for any updates, and continued along.</li>
                    </ul>
                </p>
                <Image style={{ maxWidth: '1250px', margin: '0 auto 20px'}} fluid={data.viberplacementtest.childImageSharp.fluid} />
                <SectionSubheading style={{ margin: '10px 0',}}>Sketching Out A Day in the Life</SectionSubheading>
                <p>Within the group of 15, we worked to sketch out a typical day for sales associates. This helped us better understand their movements and where there may be opportunities for trainings and workshops.
                    <ol style={{ marginTop: '5px', listStyle: 'none'}}>
                        <li><Accent className="bold">6:00AM-8:00AM:</Accent> Sales associate gets up early, checks in with manager for the day via Viber and hits the road.</li>
                        <li><Accent className="bold">8:00AM-12:00PM:</Accent> He/she heads to a village anywhere between 0-2 hours away to deliver a presentation to farmers interested in irrigation products.</li>
                        <li><Accent className="bold">12:00PM-1:00PM:</Accent> He/she stops at a cafe for lunch or if he's packed a lunch, grabs some coffee. Checks updates from team via Viber.</li>
                        <li><Accent className="bold">1:00PM-3:00PM:</Accent> He/she visits a farmer who is having trouble with a product.</li>
                        <li><Accent className="bold">3:00PM-3:30PM:</Accent> He/sheHe stops in town for a snack, maybe to top up his internet package.</li>
                        <li><Accent className="bold">3:30PM-5:00PM:</Accent> He/she heads home.</li>
                    </ol>
                </p>
                <p style={{marginBottom: 0}}>We returned to the office with a newfound apreciation for the sales associates work, motivations, and daily routines.</p>
                {/* <div style={{ border: '3px solid #f48e08', padding: '20px'}}>
                  <SectionSubheading style={{ margin: '10px 0',}}>Opportunities</SectionSubheading>
                    <ol style={{ marginTop: '5px', listStyle: 'square'}}>
                        <li><Accent className="bold">Tap into mobile:</Accent> Our users were very familiar with their mobile devices, using them daily to communicate.</li>
                        <li><Accent className="bold"></Accent> </li>
                    </ol>
                </div> */}
            </div>
        </div>
        <div style={{ backgroundColor: '#fffff', padding: rhythm(1.5),}}>
          <div style={{ maxWidth: '780px', margin: '0 auto', }}>
            <SectionHeading>Testing a Concept: Bite-Size Courses, Delivered Daily</SectionHeading>
            <p>After ideating as a team on different solutions, we decided bite-size English lessons delivered via mobile phone could potentially bring professional development training to sales associates in a way that fit into their busy schedules.
               We decided to implement a makeshift prototype to test the concept using Viber groups.</p>
            <SectionSubheading style={{ margin: '10px 0',}}>Concept Validation</SectionSubheading>
            <p>We created two distinct groups on Viber of 10 sales associates. One of the groups was the East Bago Zone, whose members were familiar with each other. The other group consisted of a random mix of sales associates from around the country.</p>
            <Image style={{ maxWidth: '600px', margin: '0 auto 20px'}} fluid={data.viberChat.childImageSharp.fluid} />
            <p >We set out to answer a few questions: <Accent className='bold'>How do sales associates respond to this type of learning? Was the format easy to understand? How comfortable were they learning in a shared group, when activities and assignments were public?</Accent></p>
            <Image style={{ maxWidth: '250px', margin: '0 auto 20px'}} fluid={data.viberscreenshot.childImageSharp.fluid} />
            <p style={{marginBottom: '10px'}}>After a two-week pilot, we met with the team at the Irrigation Q2 Meeting to get their feedback and measure English gains.</p>
          </div>
          <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', maxWidth: '790px', margin: '0 auto'}}> 
            <TopicImageWrapper>
              <Image fluid={data.viberpilot_bagoE.childImageSharp.fluid} />
              <p style={{ fontStyle: 'italic', fontSize: '.8rem', margin: '10px auto 0'}}>Bago E Zone team members providing feedback.</p>
            </TopicImageWrapper>
            <TopicImageWrapper>
              <Image fluid={data.viberpilot_mixedgroup.childImageSharp.fluid} />
              <p style={{ fontStyle: 'italic', fontSize: '.8rem', margin: '10px auto 0'}}>The second group, composed of sales associates from a variety of regions giving feedback.</p>
            </TopicImageWrapper>
          </div>
        </div>
      </Layout>
    );
  }
}

const Title = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    font-size: 3rem;
  }
`;

const SectionHeading = styled.h2`
  text-transform: none;
  font-size: 1.4rem;
  text-align: left;

  @media (min-width: 769px) {
    font-size: 1.6rem;
  }
`;

const SectionSubheading = styled.h3`
  margin-top: 70px;
`

const Accent = styled.span` 
  &.bold {
    font-weight: 600;
  }

  &.color {
    color: #f48e08;
  }

  .break {
    word-break: break-all;
  }

  .italic {
    font-style: italic;
  }
`;

const StyledMapImage = styled(Image)`
  flex: 0 0 100%;
  margin: 0 0 20px; 

  @media (min-width: 500px) {
     flex-basis: 50%;
     margin: 0 20px 0 0;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  margin: 1.2rem auto; 

  @media (min-width: 769px) {
     width: 80%;
  }
`;

const TopicImageWrapper = styled.div`
  flex: 0 0 100%;
  margin: 15px auto; 

  :last-child {
    margin-bottom: 0;
  }

  @media (min-width: 769px) {
     flex-basis: 48%;
  }
`;

const IconContainer = styled.div`
  flex: 0 0 100%;

  @media (min-width: 769px) {
     flex-basis: 40%;
  }
`;

const ResearchIconContainer = styled.div`
  flex: 0 0 80%;
  text-align: center;

  @media (min-width: 769px) {
    flex-basis: 60%;
    text-align: left;
    margin-left: 30px;
  }
`;

const LossIconContainer = styled.div`
  flex: 0 0 80%;
  text-align: center;
  margin-top: 15px;

  @media (min-width: 769px) {
    flex-basis: 60%;
    text-align: left;
    margin: 0 0 0 30px;
  }
`;

const StyledStripImage = styled(Image)`
  flex: 0 0 50%;

  @media (min-width: 769px) {
     flex-basis: 25%;
  }
`;

const StyledMethodImage = styled(Image)`
  flex: 0 1 100%;
  max-height: 500px;
`;

const Method = styled.div`
  flex: 0 1 100%;
`;

const StyledLink = styled.a`
  font-weight: 600;
  box-shadow: none;
  text-decoration: none;
  color: #000;
  border-bottom: 3px solid #000;
  font-size: 18px;

  :hover {
    border-bottom: 3px solid #f48e08;
    color: #f48e08;
  }
`;

const ProjectBackground = styled.div`
  margin-top: 40px;
  padding: 32px;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.1);
  color: rgba(92, 92, 92, 0.9);
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 30px;
  font-size: 18px;

  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
 }
`;

export default OnTheGoEnglishClass

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    backgroundImage: file(absolutePath: { regex: "/viberpilot-motorbike.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
    }
    myanmarmap: file(absolutePath: { regex: "/viberpilot-myanmarmap.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viberChat: file(absolutePath: { regex: "/viberChat.jpeg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viberplacementtest: file(absolutePath: { regex: "/viber-pilot-placement-test.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viberscreenshot: file(absolutePath: { regex: "/viber_screenshot.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viberpilot_bagoE: file(absolutePath: { regex: "/viber-pilot_bagoE.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    viberpilot_mixedgroup: file(absolutePath: { regex: "/viber-pilot_mixedgroup.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`